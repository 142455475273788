/* eslint-disable jsdoc/require-jsdoc, vue/component-definition-name-casing, vue/order-in-components */
/* global cdata, contentData, MR */
import 'focus-visible';
import "./utilities/btnRipple";
// external dependencies
import Vue from 'vue';
import AsyncComputed from 'vue-async-computed';
import VueAxios from 'vue-axios';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import Vuelidate from 'vuelidate';
import VueRouter from 'vue-router';
import PortalVue from 'portal-vue';
import FocusLock from '@components/FocusLock';
import MaskedInput from 'vue-masked-input';
import vClickOutside from 'v-click-outside';
import slick from 'vue-slick-carousel';
import * as Sentry from '@sentry/browser';
import throttle from 'lodash.throttle';
import VueLazyload from 'vue-lazyload';
import GmapVue from 'gmap-vue';

Vue.use(GmapVue, {
  // [OPTIONAL] If you intend to programmatically custom event listener code
  // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  // you might need to turn this on.
  autoBindAllEvents: true,

  // [OPTIONAL] If you want to manually install components, e.g.
  // import {GmapMarker} from 'gmap-vue/src/components/marker'
  // Vue.component('GmapMarker', GmapMarker)
  // then set installComponents to 'false'.
  // If you want to automatically install all the components this property must be set to 'true':
  installComponents: false,

  // Load the Google Maps API dynamically, if you set this to `true` the plugin doesn't load the Google Maps API
  dynamicLoad: true,
});

// internal dependencies
import store from './store';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import { getObjProperty, getObjPropertyCatchUndefined, elementInView } from './utilities/mrVueUtils';

import globalMixins from '@mixins/globalMixins.js';
import cartMixin from '@mixins/cartMixin.js';
import customerMixin from '@mixins/customerMixin.js';
import advisorMixin from '@mixins/advisorMixin.js';
import xsellMixin from '@mixins/xsellMixin.js';
import trackEventMixin from '@mixins/trackEventMixin';
import browserContextMixin from '@mixins/browserContextMixin';

import filters from './filters.js'; // TODO: remove for Vue3
import filtersPlugin from './plugins/filtersPlugin.js';

import dropdown from '@components/dropdown';
import goToPath from '@components/goToPath';
import hcbLocation from '@components/hcbLocation';
import imgBox from '@components/imgBox';
import interval from '@components/interval';
import modal from '@components/modal';
import AppModal from '@components/AppModal';
import Notifications from '@components/Notifications';
import MultiSelectDropdown from '@components/MultiSelectDropdown';
import party from '@components/party';
import proxy from '@components/proxy';
import siteMessages from '@components/siteMessages';
import SiteMessageBanner from '@components/SiteMessageBanner';
import ContentSiteMessages from '@components/ContentSiteMessages';
import splitImageCompare from '@components/splitImageCompare';
import touch from '@components/touch';
import toggle from '@components/toggle';
import toggleInterval from '@components/toggleInterval';
import ToggleInput from '@components/ToggleInput';
import trackEvent from '@components/trackEvent';
import TransitionExpand from '@components/TransitionExpand';
import TopMenu from '@components/Dashboard/TopMenu';
import SectionBlock from '@components/Dashboard/SectionBlock';
import MrDropdown from '@components/MrDropdown';
import MrIcon from '@components/MrIcon';
import CmsExposeSettings from '@components/CmsExposeSettings';
import CMSPartial from '@components/CMSPartial';
import StoreValue from '@components/StoreValue';
import AddPromo from '@components/AddPromo';
import MrSpinnerVeil from '@components/MrSpinnerVeil';
import stars from '@components/stars';
import SiteNav from '@components/SiteNav';
import MrFooter from '@components/MrFooter';
import ChatWidget from '@components/ChatWidget';
import EmailCaptureModal from '@components/EmailCapture/EmailCaptureModal';
import PhoneCaptureModal from '@components/EmailCapture/PhoneCaptureModal';
import ResponsiveImage from '@components/ResponsiveImage';
import RPJustThere from '@components/RootPerfection/JustThere';
import MaximizableVideo from '@components/Video/MaximizableVideo';
import StickyPromoDrawer from '@components/StickyPromoDrawer';
import EmailCaptureForm from '@components/EmailCapture/EmailCaptureForm';

import truncateText from './directives/truncateText';
import mrScrollAnimation from './directives/mrScrollAnimation';
import clickOutside from './directives/clickOutside';

import mrApi from './services/mrApi';
import mrApiPlugin from './plugins/mrApi';

import { VueSvgIconPlugin } from '@yzfe/vue-svgicon';

//- ROUTES
import cartAndCheckoutRoutes from '@components/CartAndCheckout/routes';
import dashboardRoutes from '@components/Dashboard/routes';
import pdpRoutes from '@components/PDP/routes';
import hairColorBarBookingRoutes from '@components/HairColorBarBooking/routes';
import hairColorChartRoutes from '@components/HairColorChart/routes';
import designSystemRoutes from '@components/DesignSystem/routes';
import categoryReviewRoutes from '@components/ReviewsByColor/routes';
import shopProductRoutes from '@components/Shop/routes';

Vue.use(VueLazyload);
Vue.use(AsyncComputed);
Vue.use(VueAxios, axios);
Vue.use(mrApiPlugin, mrApi);
Vue.use(filtersPlugin);
Vue.use(VueCookies);
Vue.use(Vuelidate);
Vue.use(VueRouter);
Vue.use(PortalVue);
Vue.use(vClickOutside);
// We install `vue-svgicon` as plugin
// and configure it to prefix all CSS
// classes with `mr-icon-`.
Vue.use(VueSvgIconPlugin, {
  tagName: 'svgicon',
  classPrefix: 'mr',
});

Vue.mixin(globalMixins);

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
// - any global component used in CMS should be registered in kebab case-.
//- Before registering it as global component consider how frequently accross different pages
//- this component will get used. Each globaly registered component adds weight to the
//- main vue bundle that gets loaded on each vue page. Perhaps dynamically loading it would suffice in components: {}
Vue.component('SiteNav', SiteNav);
Vue.component('MrFooter', MrFooter);
Vue.component('masked-input', MaskedInput);
Vue.component('slick', slick);
Vue.component('focus-lock', FocusLock);
Vue.component('dropdown', dropdown);
Vue.component('go-to-path', goToPath);
Vue.component('hcb-location', hcbLocation);
Vue.component('img-box', imgBox);
Vue.component('interval', interval);
Vue.component('modal', modal);
Vue.component('multi-select-dropdown', MultiSelectDropdown);
Vue.component('party', party);
Vue.component('proxy', proxy);
Vue.component('site-messages', siteMessages);
Vue.component('site-message-banner', SiteMessageBanner);
Vue.component('split-image-compare', splitImageCompare);
Vue.component('touch', touch);
Vue.component('toggle', toggle);
Vue.component('toggle-interval', toggleInterval);
Vue.component('toggle-input', ToggleInput);
Vue.component('track-event', trackEvent);
Vue.component('transition-expand', TransitionExpand);
Vue.component('top-menu', TopMenu);
Vue.component('section-block', SectionBlock);
Vue.component('mr-dropdown', MrDropdown);
Vue.component('mr-icon', MrIcon);
Vue.component('cms-expose-settings', CmsExposeSettings);
Vue.component('cms-partial', CMSPartial);
Vue.component('stars', stars);
Vue.component('responsive-image', ResponsiveImage);
Vue.component('store-value', StoreValue);
Vue.component('add-promo', AddPromo);
Vue.component('mr-spinner-veil', MrSpinnerVeil);
Vue.component('email-capture-form', EmailCaptureForm);

// TODO remove this declarations once we build a good solution for getting configurations from cms for vue components
Vue.component('r-p-just-there', RPJustThere);
Vue.component('maximizable-video', MaximizableVideo);
Vue.directive('truncatetext', truncateText);
Vue.directive('mrscrollanimation', mrScrollAnimation);

Vue.directive('click-outside', clickOutside);

Vue.config.errorHandler = function(err, vm, info) {
  //- have to manually log the error to console when using errorHandler
  // eslint-disable-next-line no-console
  console.error(err);

  //- send the error to Sentry for production monitoring
  if (Sentry) {
    Sentry.captureException(err);
    Sentry.setExtra('info', info);
  }
};

export const router = new VueRouter({
  mode: 'history',
  routes: [
    ...cartAndCheckoutRoutes,
    ...dashboardRoutes,
    ...pdpRoutes,
    ...hairColorBarBookingRoutes,
    ...hairColorChartRoutes,
    ...designSystemRoutes,
    ...categoryReviewRoutes,
    ...shopProductRoutes
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
        offset: {
          x: 0,
          y: 10,
        },
      };
    } else if (to.meta.noScroll) {
      return false;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) {
    return next();
  }

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

router.afterEach((to) => {
  setDyContext(to);
});

function setDyContext(to) {
  if (!window.DY) {
    return;
  }
  //- We only want to adjust the context for OTHER. The main logic for setting the context is in headerScripts.pug
  const currentContextType = window.DY.recommendationContext?.type || 'OTHER';
  if (currentContextType === 'OTHER') {
    window.DY.recommendationContext = { type: currentContextType, data: [to.path] };
  }
}

window.mrApp = new Vue({
  el: '#mrVueApp',
  store,
  router,
  components: {
    AppModal,
    EmailCaptureModal,
    PhoneCaptureModal,
    Notifications,
    ChatWidget,
    ContentSiteMessages,
    StickyPromoDrawer,
    HairColorBarMoreInfo: () => import(/* webpackChunkName: "HCB-page" */ '@components/HairColorBar/HairColorBarMoreInfo'),
    HairColorBarWaitlistForm: () => import(/* webpackChunkName: "HCB-all-locations-page" */ '@components/HairColorBar/WaitlistForm'),
    HcbIndividual: () => import(/* webpackChunkName: "HCBIndividual" */ '@components/HairColorBar/HcbIndividual'),
    HcbLocationPage: () => import(/* webpackChunkName: "HCBLocationPage" */ '@components/HairColorBar/HcbLocationPage'),
    LazyComponent: () => import(/* webpackChunkName: "LazyComponent" */ '@components/LazyComponent'),
    VideoPlayer: () => import(/* webpackChunkName: "VideoPlayer" */'@components/VideoGallery/VideoPlayer.vue'),
    VideoGallery: () => import(/* webpackChunkName: "VideoGallery" */ '@components/VideoGallery'),
    Accordion: () => import(/* webpackChunkName: "Accordion" */ '@components/Accordion'),
    AccordionSection: () => import(/* webpackChunkName: "Accordion" */ '@components/Accordion/AccordionSection'),
    ColorBarWhySection: () => import(/* webpackChunkName: "ColorBar" */ '@components/ColorBar/WhySection'),
    ColorBarResultsSection: () => import(/* webpackChunkName: "ColorBar" */ '@components/ColorBar/ResultsSection'),
    ShowMore: () => import(/* webpackChunkName: "ColorBar" */ '@components/ColorBar/ShowMore'),
    CrossSellProducts: () => import(/* webpackChunkName: "CrossSellProducts" */ '@components/CrossSellProducts'),
    OnViewport: () => import(/* webpackChunkName: "OnViewport" */ '@components/Effects/OnViewport.vue'),
    OnViewportEffect: () => import(/* webpackChunkName: "Effects" */ '@components/Effects/OnViewportEffect.vue'),
    WithEffect: () => import(/* webpackChunkName: "Effects" */ '@components/Effects/WithEffect.vue'),
    Cart: () => import(/* webpackChunkName: "Cart" */ '@components/Cart'),
    CheckoutConfirmation: () => import(/* webpackChunkName: "CheckoutConfirmation" */ '@components/CheckoutConfirmation'),
    CartAndCheckout: () => import(/* webpackChunkName: "CartAndCheckout" */ '@components/CartAndCheckout'),
    CompareShadesTool: () => import(/* webpackChunkName: "CompareShadesTool" */ '@components/CompareShadesTool'),
    PdpEntry: () => import(/* webpackChunkName: 'PdpEntry' */ '@components/PDP/PdpEntry'),
    AddProduct: () => import(/* webpackChunkName: 'AddProduct' */ '@components/AddProduct'),
    PdpBottom: () => import(/* webpackChunkName: 'PdpBottom' */ '@components/PDP/PdpBottom'),
    Pixlee: () => import(/* webpackChunkName: 'Pixlee' */ '@components/Pixlee'),
    Dashboard: () => import(/* webpackChunkName: 'Dashboard' */ '@components/Dashboard'),
    DataObject: () => import(/* webpackChunkName: 'DataObject' */ '@components/DataObject'),
    ColorSolutions: () => import(/* webpackChunkName: 'ColorSolutions' */ '@components/ColorSolutions'),
    HairColorBarBooking: () => import(/* wepackChunkName: 'HairColorBarBooking' */ '@components/HairColorBarBooking'),
    HairColorBarBookingConfirmation: () => import(/* wepackChunkName: 'HairColorBarBooking' */ '@components/HairColorBarBookingConfirmation'),
    HairColorBarBookingConfirmationV2: () => import(/* wepackChunkName: 'HairColorBarBooking' */ '@components/HairColorBarBookingConfirmationV2'),
    HowsItLanding: () => import(/* webpackChunkName: 'HowsItLanding' */ '@components/HowsItLanding'),
    ColorBarCancelationConfirmation: () => import(/* webpackChunkName: 'ColorBarCancelationConfirmation' */ '@components/ColorBar/ColorBarCancelationConfirmation'),
    MrNewsletterSubscription: () => import(/* webpackChunkName: 'MrNewsletterSubscription' */ '@components/MrNewsletterSubscription'),
    ymkWrap: () => import(/* webpackChunkName: 'ymkWrap' */ '@components/ymkWrap'),
    UnlimitedRootsMembershipPricing: () => import(/* webpackChunkName: 'UnlimitedRootsMembershipPricing' */ '@components/UnlimitedRootsMembershipPricing'),
    calendar: () => import(/* webpackChunkName: 'calendar' */ '@components/calendar'),
    applyPromo: () => import(/* webpackChunkName: 'applyPromo' */ '@components/applyPromo'),
    product: () => import(/* webpackChunkName: 'product' */ '@components/product'),
    products: () => import(/* webpackChunkName: 'products' */ '@components/products'),
    SiteMessageBannerCarousel: () => import(/* webpackChunkName: 'SiteMessageBannerCarousel' */ '@components/SiteMessageBannerCarousel'),
    SiteMessageSlot: () => import(/* webpackChunkName: 'SiteMessageSlot' */ '@components/SiteMessageSlot'),
    StarsInput: () => import(/* webpackChunkName: 'StarsInput' */ '@components/StarsInput'),
    SponsoredReview: () => import(/* webpackChunkName: 'SponsoredReview' */ '@components/SponsoredReview'),
    ReviewsByColor: () => import(/* webpackChunkName: 'ReviewsByColor' */ '@components/ReviewsByColor'),
    CategoryReviewCount: () => import(/* webpackChunkName: 'CategoryReviewCount' */ '@components/CategoryReviewCount'),
    stickyWrap: () => import(/* webpackChunkName: 'stickyWrap' */ '@components/StickyWrap'),
    Quiz: () => import(/* webpackChunkName: 'Quiz' */ '@components/Quiz'),
    QuizV2: () => import(/* webpackChunkName: 'QuizV2' */ '@components/QuizV2'),
    QuizWithEmailCapture: () => import(/* webpackChunkName: 'QuizWithEmailCapture' */ '@components/QuizWithEmailCapture'),
    OrderSurvey: () => import(/* webpackChunkName: 'OrderSurvey' */ '@components/OrderSurvey'),
    YoutubePlayer: () => import(/* webpackChunkName: 'youtubePlayer' */ '@components/youtubePlayer'),
    mrVideo: () => import(/* webpackChunkName: 'mrVideo' */ '@components/mrVideo/mrVideo'),
    MultiVideoPlayer: () => import(/* webpackChunkName: 'MultiVideoPlayer' */'@components/MultiVideoPlayer'),
    MrSiteSearch: () => import(/* webpackChunkName: 'MrSiteSearch' */ '@components/mrSiteSearch'),
    MensMenu: () => import(/* webpackChunkName: 'MensMenu' */ '@components/MensMenu'),
    DesignSystem: () => import(/* webpackChunkName: 'DesignSystem' */ '@components/DesignSystem'),
    MrSignIn: () => import(/* webpackChunkName: 'MrSignIn' */ '@components/MrSignIn'),
    MrSignInV2: () => import(/* webpackChunkName: 'MrSignInV2' */ '@components/MrSignInV2'),
    ColorConsultation: () => import(/* webpackChunkName: 'ColorConsultation' */ '@components/ColorConsultation'),
    WhyMR: () => import(/* webpackChunkName: 'HomePage' */ '@components/HomePage/WhyMR'),
    ReturnUserModule: () => import(/* webpackChunkName: "HomePage" */ '@components/HomePage/ReturnUserModule'),
    UgcWithReviews: () => import(/* webpackChunkName: 'HomePage' */ '@components/HomePage/UgcWithReviews'),
    ContactUs: () => import(/* webpackChunkName: 'HomePage' */ '@components/HomePage/ContactUs'),
    HomeHero: () => import(/* webpackChunkName: "HomePage" */ '@components/HomePage/HomeHero'),
    HomeBlogSection: () => import(/* webpackChunkName: 'HomePage' */ '@components/HomePage/HomeBlogSection'),
    ProductSensitivity: () => import(/* webpackChunkName: 'ProductSensitivity' */ '@components/ProductSensitivity'),
    ProductSensitivityWaiver: () => import(/* webpackChunkName: 'ProductSensitivityWaiver' */ '@components/ProductSensitivity/Waiver'),
    LoyaltyStaticThermometer: () => import(/* webpackChunkName: 'LoyaltyStaticThermometer' */ '@components/HairColorBarMembership/LoyaltyStaticThermometer'),
    QuizOnQ: () => import(/* webpackChunkName: 'QuizOnQ' */ '@components/QuizOnQ'),
    Region: () => import(/* webpackChunkName: 'Region' */ '@components/Regions/Region'),
    NearestRegion: () => import(/* webpackChunkName: 'NearestRegion' */ '@components/Regions/NearestRegion'),
    RegionList: () => import(/* webpackChunkName: 'RegionList' */ '@components/Regions/RegionList'),
    CardsCarousel: () => import(/* webpackChunkName: 'CardsCarousel' */ '@components/Carousels/CardsCarousel'),
    GreenhouseJobBoard: () => import(/* webpackChunkName: 'GreenhouseJobBoard' */ '@components/Greenhouse/GreenhouseJobBoard'),
    GreenhouseJobBoardWithSearch: () => import(/* webpackChunkName: 'GreenhouseJobBoard' */ '@components/Greenhouse/GreenhouseJobBoardWithSearch'),
    GreenhouseJobApplication: () => import(/* webpackChunkName: 'GreenhouseJobApplication' */ '@components/Greenhouse/GreenhouseJobApplication'),
    Shop: () => import(/* webpackChunkName: 'Shop' */ '@components/Dashboard/Shop'),
    ExpertiseIncluded: () => import(/* webpackChunkName: 'ExpertiseIncluded' */ '@components/ExpertiseIncluded'),
    VideoChatReasonDropdown: () => import(/* webpackChunkName: 'VideoChatReasonDropdown' */ '@components/VideoChat/ScheduleVideoChat/VideoChatReasonDropdown'),
    VideoChatCancellation: () => import(/* webpackChunkName: 'VideoChatCancellation' */ '@components/VideoChat/ScheduleVideoChat/VideoChatCancellation'),
    VideoChatHero: () => import(/* webpackChunkName: 'VideoChatHero' */ '@components/VideoChat/ScheduleVideoChat/VideoChatHero'),
    VideoChatOnDemand: () => import(/* webpackChunkName: 'VideoChatOnDemand' */ '@components/VideoChat/ScheduleVideoChat/VideoChatOnDemand'),
    VideoChatFaqs: () => import(/* webpackChunkName: 'VideoChatFaqs' */ '@components/VideoChat/ScheduleVideoChat/VideoChatFaqs'),
    BrowsableProductLine: () => import(/* webpackChunkName: 'BrowsableProductLine' */ '@components/BrowsableProductLine'),
    RebootBeforeAfterCarousel: () => import(/* webpackChunkName: 'RebootBeforeAfterCarousel' */ '@components/RebootBeforeAfterCarousel'),
    RebootComparisonChart: () => import(/* webpackChunkName: 'RebootComparisonChart' */ '@components/RebootComparisonChart'),
    ProductsSwatchesWithUgc: () => import(/* webpackChunkName: 'ProductsSwatchesWithUgc'*/ '@components/ProductsSwatchesWithUgc'),
    UgcHomeCarousel: () => import(/* webpackChunkName: 'UgcHomeCarousel' */ '@components/UgcHomeCarousel'),
    FeaturedPress: () => import(/* webpackChunkName: 'Press' */ '@components/Press/FeaturedPress'),
    PressItems: () => import(/* webpackChunkName: 'Press' */ '@components/Press/PressItems'),
    MrNavigation: () => import(/* webpackChunkName: 'MrNavigation' */ '@components/MrNavigation'),
    GiftCardForm: () => import(/* webpackChunkName: 'GiftCardForm' */ '@components/GiftCardForm'),
    Breadcrumbs: () => import(/* webpackChunkName: 'Breadcrumbs' */ '@components/Breadcrumbs'),
    EmailSubscriptionPreferences: () => import(/* webpackChunkName: 'EmailSubscriptionPreferences' */ '@components/EmailSubscriptionPreferences'),
    JustForBestExperience: () => import(/* webpackChunkName: 'JustForBestExperience' */ '@components/Experience/JustForBestExperience'),
    HairColorSimulator: () => import(/* webpackChunkName: 'HairColorSimulator' */ '@components/AugmentedReality/HairColorSimulator'),
    ReinventedAccordion: () => import(/* webpackChunkName: 'ReinventedAccordion' */ '@components/ReinventedAccordion/ReinventedAccordion'),
    HighLiftChart: () => import(/* webpackChunkName: 'HighLiftChart' */ '@components/HighLiftChart'),
    PressFactsheetComponent: () => import(/* webpackChunkName: 'PressFactsheetComponent' */ '@components/Press/PressFactsheetComponent'),
    PressAssetComponent: () => import(/* webpackChunkName: 'PressAssetComponent' */ '@components/Press/PressAssetComponent'),
    PressReleaseList: () => import(/* webpackChunkName: 'PressReleaseList' */ '@components/Press/PressReleaseList'),
    HCBCareersHero: () => import(/* webpackChunkName: 'HCBCareers' */ '@components/HairColorBar/Careers/HCBCareersHero'),
    HCBCareersTraits: () => import(/* webpackChunkName: 'HCBCareers' */ '@components/HairColorBar/Careers/HCBCareersTraits'),
    HCBCareersPress: () => import(/* webpackChunkName: 'HCBCareers' */ '@components/HairColorBar/Careers/HCBCareersPress'),
    HCBCareersTestimonials: () => import(/* webpackChunkName: 'HCBCareers' */ '@components/HairColorBar/Careers/HCBCareersTestimonials'),
    HCBCareersVideos: () => import(/* webpackChunkName: 'HCBCareers' */ '@components/HairColorBar/Careers/HCBCareersVideos'),
    ImageCarouselQuickView: () => import(/* webpackChunkName: 'ImageCarouselQuickView' */ '@components/ImageCarouselQuickView'),
    ProductCarousel: () => import(/* webpackChunkName: 'ProductCarousel' */ '@components/ProductCarousel'),
    ProductCard: () => import(/* webpackChunkName: 'ProductCard' */ '@components/ProductCard'),
    Search: () => import(/* webpackChunkName: 'Search' */ '@components/Search'),
    ColorBarMapSection: () => import(/* webpackChunkName: 'ColorBarMapSection' */ '@components/ColorBarMapSection'),
    ColorBarMapSectionV2: () => import(/* webpackChunkName: 'ColorBarMapSectionV2' */ '@components/ColorBarMapSectionV2'),
    HcbLocationShow: () => import(/* webpackChunkName: 'HcbLocationShow' */ '@components/HairColorBarMembership/HcbLocationShow'),
    HcbLocationSearch: () => import(/* webpackChunkName: 'HcbLocationSearch' */ '@components/HairColorBarMembership/HcbLocationSearch'),
    HcbLocationSearchResultModal: () => import(/* webpackChunkName: 'HcbLocationSearch' */ '@components/HairColorBarMembership/HcbLocationSearchResultModal'),
    HairColorBarMenu: () => import(/* webpackChunkName: 'HairColorBarMenu' */ '@components/HairColorBar/HairColorBarMenu'),
    HairColorBarMembership: () => import(/* webpackChunkName: 'HairColorBarMembership' */ '@components/HairColorBarMembership'),
    RPJustThere: () => import(/* webpackChunkName: 'RootPerfection' */ '@components/RootPerfection/JustThere'),
    ToggleableCards: () => import(/* webpackChunkName: 'ToggleableCards' */ '@components/Cards/ToggleableCards'),
    ServicePackageTile: () => import(/* webpackChunkName: 'ServicePackageTile' */ '@components/ServivePackageTile'),
    BlogPosts: () => import(/* webpackChunkName: 'Blogs' */ '@components/Blog/BlogPosts'),
    HelpCenter: () => import(/* webpackChunkName: 'HelpCenter' */ '@components/HelpCenter'),
    MrSpinnerVeil: () => import(/* webpackChunkName: 'MrSpinner' */ '@components/MrSpinnerVeil'),
    ColorPlusProduct: () => import(/* webpackChunkName: 'ColorPlusProduct' */ '@components/ColorPlusProduct'),
    HcbServicesOffered: () => import(/* webpackChunkName: 'HairColorBarBooking' */ '@components/HcbServicesOffered'),
    ColorwonderExpectations: () => import(/* webpackChunkName: 'ColorwonderExpectations' */ '@components/ColorwonderExpectations'),
    StoreLocator: () => import(/* webpackChunkName: 'StoreLocator' */ '@components/StoreLocator'),
    ShopByColor: () => import(/* webpackChunkName: 'ShopByColor' */ '@components/ShopByColor'),
    PhoneCaptureHero: () => import(/* webpackChunkName: 'PhoneCapture' */ '@components/EmailCapture/PhoneCaptureHero'),
    ServicesAccordion: () => import(/* webpackChunkName: 'ServicesLanding' */ '@components/ServicesLanding/ServicesAccordion'),
    ColorWonderHero: () => import(/* webpackChunkName: 'ColorWonderHero' */ '@components/ColorWonder/ColorWonderHero')
  },

  mixins: [cartMixin, customerMixin, advisorMixin, xsellMixin, trackEventMixin, browserContextMixin],

  data() {
    return {
      signInControl: {
        open: false,
      },

      footerControl: {
        selected: '',
      },

      chatAvailable: false,
      accessibilityViewed: false,
    };
  },
  //- Please use this very selectively
  provide: {
    getObjProperty,
    getObjPropertyCatchUndefined,
    elementInView,
  },

  created() {
    this.setMRConfig(this.MRConfig);
    this.loadCustomer();
    this.setCdata(cdata);
  },

  computed: {
    appModalVisible() {
      return this.$store.state.modal.visible;
    },

    ...mapState('global', [
      'windowData',
      'breadcrumbs',
      'hideLogin'
    ]),

    ...mapState('siteNav', [
      'topNavSelected',
      'topNavMobileNavOpen',
      'searchOpen',
      'navItems',
      'siteNavMobileNavOpen'
    ]),

    ...mapState('customer', ['info', 'cdata']),

    ...mapState('cart', ['cart', 'refillCart', 'addingProduct', 'removingProduct']),

    ...mapState('cms', ['cmsSettings']),

    ...mapGetters('subscriptions', ['subsGroupedByNextWithOnTheWay', 'promotedReactivatableSubs']),

    ...mapGetters('cart', ['cartContainsOnlyMensProducts']),

    ...mapGetters('siteNav', ['showMensMenu']),

    MRConfig() {
      return MR.config;
    },

    mobileMenuOpen() {
      return this.topNavMobileNavOpen || this.siteNavMobileNavOpen;
    },

    contentData() {
      let data = {};

      if (contentData) {
        data = contentData;
      }

      return data;
    },

    urlParams() {
      var search = window.location.search;
      if (!search) {
        //- need to support some old angular redirect urls that contain hash (for now)
        let hash = window.location.hash;

        if (!hash) {
          return {};
        }

        search = hash.replace(/^#/, '');
      }

      var params = {};
      search
        .replace(/^\?/, '')
        .split('&')
        .forEach(function(paramStr) {
          var part = paramStr.split('=');
          params[part[0]] = part[1];
        });
      return params;
    },

    customerInfo() {
      return this.$store.state.customer.info;
    },

    inColorAdvisorEmailCaptureExperiment() {
      return this.experiments["Advisor Email Capture 2022"] === "B";
    },
  },

  methods: {
    ...mapActions(['notify', 'removeNotification', 'notifySuccess']),

    ...mapActions('global', [
      'toggleSearch'
    ]),

    ...mapActions('modal', [
      'showModal',
      'showPrompt'
    ]),

    ...mapActions('customer', [
      'loadCustomer',
      'refreshCustomerCdata'
    ]),

    ...mapActions('cart', ['addToCart', 'removeFromCart', 'addMembershipToCart', 'updateCartFromCookies']),

    ...mapActions('subscriptions', ['initSubscriptions']),

    ...mapActions('emailCapture', ['runEmailCapture']),

    ...mapActions('siteNav', [
      'toggleMobileSiteNav'
    ]),

    ...mapActions('partial', [
      'showPartialModal',
    ]),

    ...mapActions('coupons', [
      'initCoupons'
    ]),

    ...mapMutations('customer', ['setCdata']),

    ...mapMutations('global', [
      'setMRConfig',
      'setWindowData',
      'setExperiments'
    ]),

    ...mapMutations('siteNav', [
      'addMenuItem',
      'removeMenuItem',
      'setSiteNavMobileNavOpen',
      'setTopNavSelected',
      'setTopNavMobileNavOpen',
      'setSearchOpen',
    ]),

    openLogInModal() {
      let payload = {
        component: 'MrSignInV2Modal',
        theme: 'signin',
      };

      this.showModal(payload);
    },

    toggleHamburger() {
      if (!this.showMensMenu) {
        this.toggleMobileSiteNav();
      } else {
        this.setTopNavMobileNavOpen(!this.topNavMobileNavOpen);
      }
    },

    onResizeEventCallback() {
      const previousWidth = this.windowData.width;
      const previousHeight = this.windowData.height;
      const previousBreakpoint = this.windowData.breakpoint;

      this.setWindowData(['width', window.innerWidth]);
      this.setWindowData(['height', window.innerHeight]);
      this.setWindowData(['scrollBottom', window.pageYOffset + window.innerHeight]);

      this.updateBreakpoint();

      if (previousWidth != this.windowData.width) {
        this.$emit('width-change', this.windowData);
      }
      if (previousHeight != this.windowData.height) {
        this.$emit('height-change', this.windowData);
      }
      if (previousBreakpoint != this.windowData.breakpoint) {
        this.$emit('breakpoint-change', this.windowData);
      }
    },

    onScrollEventCallback() {
      if (this.windowData.scrollTop != window.pageYOffset) {
        const oldScrollTop = this.windowData.scrollTop;
        this.setWindowData(['scrollTop', window.pageYOffset]);
        this.setWindowData(['scrollBottom', window.pageYOffset + window.innerHeight]);
        this.setWindowData(['scrollDirection', oldScrollTop > this.windowData.scrollTop ? -1 : 1]);
        this.setWindowData(['scrollDepth', (this.windowData.scrollBottom / this.$el.clientHeight) * 100]);
        this.setWindowData(['scrolled', this.windowData.scrolled || this.windowData.scrollTop > 0]);
        this.$emit('scroll-change', this.windowData);
        // browser hidden nav detection
        this.setWindowData(['heightOnScroll', window.innerHeight]);

        if (this.windowData.heightOnScroll != this.windowData.oldHeightOnScroll) {
          if (this.windowData.heightOnScroll - 25 > this.windowData.oldHeightOnScroll) {
            // innerHeight increased indicating mobile nav is hidden
            this.setWindowData(['mobileNavHidden', true]);
          } else if (this.windowData.heightOnScroll + 25 < this.windowData.oldHeightOnScroll) {
            // innerHeight decreased indicating mobile nav is visible
            this.setWindowData(['mobileNavHidden', false]);
          }

          this.setWindowData(['oldHeightOnScroll', this.windowData.heightOnScroll]);
        }

        // scrolled depth tracking
        if (this.contentData.depthTracking) {
          const trackingData = {
            url: window.location.href,
            breakpoint: this.windowData.breakpoint,
            uri: this.contentData.uri,
            variationId: this.contentData.variationId,
            targetPlatform: this.contentData.targetPlatform,
            variationKey: this.contentData.variationKey,
            variationName: this.contentData.variationName,
            experimentId: this.contentData.experimentId,
            experimentName: this.contentData.experimentName,
          };

          if (this.windowData.scrollDepth > 25 && this.windowData.scrolledDepth < 25) {
            trackingData.percent = 25;
            this.trackEvent('Scroll Depth', trackingData);
          }
          if (this.windowData.scrollDepth > 50 && this.windowData.scrolledDepth < 50) {
            trackingData.percent = 50;
            this.trackEvent('Scroll Depth', trackingData);
          }
          if (this.windowData.scrollDepth > 75 && this.windowData.scrolledDepth < 75) {
            trackingData.percent = 75;
            this.trackEvent('Scroll Depth', trackingData);
          }
          if (this.windowData.scrollDepth >= 100 && this.windowData.scrolledDepth < 100) {
            trackingData.percent = 100;
            this.trackEvent('Scroll Depth', trackingData);
          }
        }

        // scrolledDepth should updated after depth tracking
        this.setWindowData([
          'scrolledDepth',
          this.windowData.scrollDepth > this.windowData.scrolledDepth ? this.windowData.scrollDepth : this.windowData.scrolledDepth,
        ]);
      }

      if (!this.accessibilityViewed) {
        this.accessibilityViewed = !!document.getElementById('ada_help_container');
        if (this.accessibilityViewed) {
          // should only trigger one time when the ada_help_container gets added to the page
          this.trackEvent('Accessiblity Widget Viewed');
        }
      }
    },
    // deprecated, use mix_logOut
    logOut(destinationPath) {
      this.mix_logOut(destinationPath);
    },

    confirmLogout() {
      let promptPayload = {
        component: 'ConfirmDialogTemplateModal',
        theme: 'flex-width',
        confirm: this.logOut,
        confirmText: 'Log Out',
        title: 'Log Out',
        text: 'Please confirm you want to sign out of your Madison Reed account.',
        centerText: true,
      };

      this.showPrompt(promptPayload);
    },

    //- checks error is user not logged in error, if so will redirect to signin and return true
    checkUserLoginError(errObj) {
      if (!(errObj && errObj.data && errObj.data.message)) {
        return false;
      }
      //If the error message has 'not logged in'
      var isNotLogged = ~errObj.data.message.indexOf('not logged in');
      //Redirect to login page
      var errorDetails = errObj.data.errorDetails || [];
      var doRedirect = errorDetails.some(function(detail) {
        return detail.doRedirect;
      });

      if (doRedirect && isNotLogged) {
        window.location.reload();
        return true;
      }
      return false;
    },

    log(message) {
      /* eslint-disable-next-line */
      console.log(message);
    },

    setFixedCtaClass(hasFixedCta) {
      document.body.classList.toggle('has-fixed-cta', hasFixedCta);
    },

    checkAuth() {
      if (this.info.authed) {
        this.initSubscriptions();
      }
    },

    checkBrowsers() {
      var ua = navigator.userAgent || navigator.vendor || window.opera;
      if (ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1) {
        this.setWindowData(['facebookBrowser', true]);
      }
      if (ua.indexOf('Instagram') > -1) {
        this.setWindowData(['instagramBrowser', true]);
      }
      if (ua.indexOf('Pinterest') > -1) {
        this.setWindowData(['pinterestBrowser', true]);
      }
      if (ua.includes('iPhone')) {
        this.setWindowData(['isIphone', true]);
      }
      if (ua.includes('iPod')) {
        this.setWindowData(['isIphone', true]);
      }
      if (ua.includes('iPad')) {
        this.setWindowData(['isIpod', true]);
      }
      if (this.windowData.isIphone || this.windowData.isIpod || this.windowData.isIpad) {
        this.setWindowData(['isIosDevice', true]);
      }
    },

    updateBreakpoint() {
      let breakpoint;

      if (this.windowData.width <= 559) {
        breakpoint = 'mobile';
      } else if (this.windowData.width <= 759) {
        breakpoint = 'tablet';
      } else if (this.windowData.width <= 959) {
        breakpoint = 'desktop-md';
      } else {
        breakpoint = 'desktop';
        this.setTopNavMobileNavOpen(false);
        this.setSiteNavMobileNavOpen(false);
      }

      this.setWindowData(['breakpoint', breakpoint]);
    },
  },

  mounted() {
    this.checkAuth();
    if (!this.cdata) {
      this.refreshCustomerCdata();
    }

    // set windowData
    this.checkBrowsers();
    this.setWindowData(['width', window.innerWidth]);
    this.setWindowData(['height', window.innerHeight]);
    this.setWindowData(['oldHeightOnScroll', window.innerHeight]);
    this.setWindowData(['heightOnScroll', window.innerHeight]);
    this.setWindowData(['scrollTop', window.pageYOffset]);
    this.setWindowData(['scrollBottom', window.pageYOffset + window.innerHeight]);
    this.setWindowData(['scrollDepth', this.windowData.scrollBottom / this.$el.clientHeight * 100]);
    this.setWindowData(['scrolledDepth', 0]);
    this.setWindowData(['scrolled', this.windowData.scrolled || this.windowData.scrollTop > 0]);
    this.updateCartFromCookies();

    if (window && window.isIOS) {
      this.setWindowData(['isIOSApp', true]);
    }

    if (this.urlParams.st) {
      this.scrollTo(this.urlParams.st, 300, 400);
    }
    this.updateBreakpoint();

    const throttledOnResizeEvent = throttle(this.onResizeEventCallback, 200);
    window.addEventListener('resize', throttledOnResizeEvent, false);

    const throttledOnScrollEvent = throttle(this.onScrollEventCallback, 200);
    window.addEventListener('scroll', throttledOnScrollEvent, false);

    const { utm_partial_type, utm_partial } = this.urlParams;
    if (utm_partial_type && utm_partial) {
      if (utm_partial_type === 'modal') {
        this.showPartialModal(utm_partial);
      }
    }

    if (!this.urlParams['extole_zone_name'] || !this.inColorAdvisorEmailCaptureExperiment) {
      this.runEmailCapture();
    }

    this.initCoupons();
    this.setExperiments(window.experiments || {});
    window.trackExperimentViewed = this.mix_trackExperimentViewed;
    this.runSegmentTracking();

    window.addToCart = this.addToCart;
  }
});